import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { loadGlobalHidden } from '../../actions/loadGlobalHidden'
import { loadSubmById } from '../../actions/loadSubmissions'
import FormActionsGlobal from '../../builder/FormActionsGlobal'
import FormBaseRedux from '../../builder/forms/FormBaseRedux'
import FormNavigation from '../../builder/navigation/FormNavigation'
import Builder from '../../builder_v1/Builder'
import { useEffectStart } from '../../hooks/useEffectStart'
import { useWindowSize } from '../../hooks/useWindowSize'
import { clearDynamEnum } from '../../redux/dynamEnum/dynamEnumSlice'
import {
  clearFormAndReqErrorsState,
  clearFormMandatoryForbiden,
  clearGlobalHidden,
  clearReqMandatoryForbiden,
} from '../../redux/formErrors/formErrorsSlice'
import { clearFormState } from '../../redux/formState/formStateSlice'
import {
  selectFormLoadErr,
  selectFormWithRequest,
  selectLoadFormData,
  selectLoadReqData,
} from '../../redux/formState/selectors'
import { selectFormIsFullInit, selectFormTreeIsBig } from '../../redux/formTree/formTreeSelectors'
import { clearFormTree, FORM_SIZE } from '../../redux/formTree/formTreeSlice'
import { useAppDispatch } from '../../redux/store'
import {
  selectLoading,
  selectSchemaFull,
  selectSchemaIsV1,
  selectSubmForm,
  selectSubmMainInfo,
} from '../../redux/submissions/selectors'
import { clearSubmissions } from '../../redux/submissions/submissionsSlice'
import ArrayCounterHelper from '../../services/ArrayCounterHelper'
import ArrayLength from '../../services/ArrayLength'
import DynamEnumDebounce from '../../services/DynamEnumDebounce'
import FormFullInit from '../../services/FormFullInit'
import AlertError from '../Alert/AlertError'
import FormHeader from '../FormHeader/FormHeader'
import FormNameInput from '../FormNameInput/FormNameInput'
import Loading from '../Loading/Loading'
import FormConnectionV2 from './FormConnectionV2'

interface iFormDetail {
  id?: string
}

const AlertBigForm = () => {
  const isBigFormTree = useSelector(selectFormTreeIsBig)

  if (!isBigFormTree) {
    return null
  }

  return (
    <div role='alert' className='gov-alert gov-alert--warning'>
      <div className='gov-alert__content'>
        {isBigFormTree === FORM_SIZE.BIG ? (
          <p>Formulář obsahuje velké množství dat, vyčkejte ...</p>
        ) : (
          <p>Formulář obsahuje extrémní množství dat, vyčkejte ..</p>
        )}
      </div>
    </div>
  )
}

const FormDetailNew = ({ id }: iFormDetail) => {
  const { isMobile } = useWindowSize()
  const formRef = useRef(null)

  const dispatch = useAppDispatch()
  const loading = useSelector(selectLoading)
  const isFullInit = useSelector(selectFormIsFullInit)

  const withReq = useSelector(selectFormWithRequest)
  const formLoadErr = useSelector(selectFormLoadErr)

  const isV1 = useSelector(selectSchemaIsV1)
  const { isDraft, loaded, submMain } = useSelector(selectSubmMainInfo)

  const loadFormData = useSelector(selectLoadFormData)
  const loadReqData = useSelector(selectLoadReqData)

  useEffect(() => {
    return () => {
      dispatch(clearFormState())
      dispatch(clearSubmissions())
      dispatch(clearFormAndReqErrorsState())
      dispatch(clearFormMandatoryForbiden())
      dispatch(clearReqMandatoryForbiden())
      dispatch(clearGlobalHidden())
      dispatch(clearFormTree())
      dispatch(clearDynamEnum())
    }
  }, [])

  useEffectStart(() => {
    if (id) {
      dispatch(loadSubmById(id))
    }
  })

  useEffect(() => {
    if (id && loaded && !isDraft && !isV1 && !loading) {
      dispatch(loadGlobalHidden(id))
    }
  }, [id, isDraft, loaded, loading])

  const resData = useSelector(selectSubmForm)
  const schema = useSelector(selectSchemaFull)
  const [loadingInit, setLoadingInit] = useState(true)

  useEffect(() => {
    if (loadingInit && !loading && schema && resData && submMain?.id === id) {
      if (isV1) {
        FormFullInit.setNoInit()
      } else {
        DynamEnumDebounce.clear()
        ArrayLength.clear()
        ArrayCounterHelper.clear()
        FormFullInit.clear()
        FormFullInit.setIsDraft(isDraft)
        FormFullInit.setFormData(resData.data)
        FormFullInit.setFormDataSchema(schema.layout)
      }
      setLoadingInit(false)
    }
  }, [loadingInit, loading, schema, resData, submMain])


  if (formLoadErr) {
    return <AlertError msg={formLoadErr} />
  }

  if (loading || loadingInit) {
    return (
      <>
        <AlertBigForm />
        <Loading />
      </>
    )
  }

  return (
    <>
      <div ref={formRef}>
        <FormHeader />

        <div className='formsContent u-mb--50'>
          <FormNameInput />

          {isV1 ? (
            <>
              {/*<Suspense fallback={<Loading />}>*/}
              {/* todo lazy load builder v1 */}
              <Builder />
              {withReq && <Builder isRequest />}
              {/*</Suspense>*/}
            </>
          ) : (
            <>
              <div className={'formWrap ' + (isMobile ? 'formWrapMobile' : 'formWrapDesktop')}>
                <FormNavigation />

                <div className='formsContentWrap'>
                  {!isFullInit && (
                    <>
                      <AlertBigForm />
                      <Loading />
                    </>
                  )}

                  <div style={{ visibility: isFullInit ? 'visible' : 'hidden' }}>
                    <FormBaseRedux />
                    {withReq && <FormBaseRedux isRequest />}
                  </div>
                  {withReq && loadFormData && loadReqData && <FormConnectionV2 />}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <FormActionsGlobal id={id!} formRef={formRef} />
    </>
  )
}

export default FormDetailNew
