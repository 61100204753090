import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import React, { useEffect, useMemo, useState } from 'react'
import { Dash, Plus } from 'react-bootstrap-icons'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { shallowEqual, useSelector } from 'react-redux'
import ButtonGov from '../../../components/Btns/ButtonGov'
import TextTrim from '../../../components/TextTrim/TextTrim'
import { makeFindFormLiveForbidenRe } from '../../../redux/formErrors/selectorsMake'
import { selectForceOpenPath, selectForceToSection } from '../../../redux/formTree/formTreeSelectors'
import { RootState } from '../../../redux/rootReducer'
import ArrayLength from '../../../services/ArrayLength'
import { defaultDeepNoArray } from '../../../utils/defaultDeepNoArray'
import { scopePartToPath, scopeWithoutRepeat } from '../../../utils/scopeModifs'
import { DisabledArrayContextContextProvider } from '../../context/DisabledArrayContext'
import { useElementContext } from '../../context/ElementContext'
import { IndexesContextProvider } from '../../context/IndexesContext'
import { useFormRenderContext } from '../../context/RenderFormContext'
import DynamEnumWatch from '../../form_elements/DynamEnumWatch'
import { useFormDeleteForbiden } from '../../hooks/useFormDeleteForbiden'
import { useFormLiveValidation } from '../../hooks/useFormLiveValidation'
import { useFormTreeEl } from '../../hooks/useFormTreeEl'
import { iUIschema } from '../../interfaces/iUiSchema'
import SectionHeaderNav from '../../navigation/SectionHeaderNav'
import RenderElements from '../../renders/RenderElements'
import { modifUiSchemaArray } from '../../utils/modifUiSchemaArray'
import { scrollToPath } from '../../utils/scrollToPath'
import NavigationAnchor from '../parts/NavigationAnchor'
import PartGovDescription from '../parts/PartGovDescription'
import PartGovErrorsBlock from '../parts/PartGovErrorsBlock'

const ArrayGroup = () => {
  const [closeIndex, setCloseIndex] = useState<number[]>([])

  const formTreeEl = useFormTreeEl()
  const forceOpenPath = useSelector(selectForceOpenPath)
  useEffect(() => {
    if (closeIndex.length && forceOpenPath) {
      closeIndex.forEach((indexVal) => {
        const closePath = path + '.' + indexVal
        if (forceOpenPath.startsWith(closePath)) {
          toggleIndex(indexVal)
        }
      })
    }
  }, [forceOpenPath])

  const forceOpenToSection = useSelector(selectForceToSection)
  useEffect(() => {
    if (closeIndex.length && forceOpenToSection) {
      closeIndex.forEach((indexVal) => {
        const formTreeChild = formTreeEl?.childs?.[indexVal]
        if (formTreeChild?.toInside?.includes(forceOpenToSection)) {
          toggleIndex(indexVal)
        }
      })
    }
  }, [forceOpenToSection])

  const toggleIndex = (index: number) => {
    if (closeIndex.includes(index)) {
      setCloseIndex(closeIndex.filter((i) => i !== index))
    } else {
      setCloseIndex([...closeIndex, index])
    }
  }
  const { validateFormLive } = useFormLiveValidation()

  const { dateSchemaScope, withSchemaDefaults, isReq } = useFormRenderContext()
  const { uiSchema, path, disabled, dataSchema, defaultValue, id } = useElementContext()

  const { setValue, getValues } = useFormContext()

  const { scope, elements, label } = uiSchema

  const identifierScope =
    scope && uiSchema.options?.arrayIdentifierScope
      ? scopeWithoutRepeat(scope) + '/' + uiSchema.options?.arrayIdentifierScope
      : undefined
  const idElement = identifierScope ? dateSchemaScope[identifierScope] : undefined

  const idRequired = idElement ? idElement?.validations?.required : true
  const copyAble = uiSchema.options?.arrayCopyable

  const min = dataSchema?.minItems ?? (idRequired ? 1 : 0)
  const max = dataSchema?.maxItems
  const btnAddLabel = 'Vložit další'
  const btnRemoveLabel = 'odebrat'
  const btnCopyLabel = 'Kopírovat'
  const btnNewLabel = 'Vložit'

  const defVal: any = defaultValue ?? {}

  const { fields, append, remove } = useFieldArray({
    name: path,
    rules: {
      minLength: min && {
        value: min,
        message: `Minimalne ${min} polozky`,
      },
      maxLength: max && {
        value: max,
        message: `Maximalne ${max} polozky`,
      },
    },
    shouldUnregister: true,
  })

  const fieldsLength = fields.length || 0
  const isFull = max && fieldsLength >= max
  const isMin = min && fieldsLength <= min

  useEffect(() => {
    ArrayLength.setLength(path, fieldsLength)
  }, [fieldsLength])

  useEffect(() => {
    return () => {
      ArrayLength.setLength(path, 0)
    }
  }, [])

  const identifierRelativePath = uiSchema.options?.arrayIdentifierScope
    ? scopePartToPath(uiSchema.options?.arrayIdentifierScope)
    : undefined

  const labelRelativePath = uiSchema.options?.arrayLabelScope
    ? scopePartToPath(uiSchema.options?.arrayLabelScope)
    : undefined

  const identifierRegexpPath =
    path && identifierRelativePath ? new RegExp(path + '.\\d+.' + identifierRelativePath) : undefined

  const findForbidenMsgsRe = useMemo(makeFindFormLiveForbidenRe(isReq), [])
  const disabledIdentifierMsgs = useSelector(
    (state: RootState) => findForbidenMsgsRe(state, identifierRegexpPath),
    shallowEqual,
  )
  const disabledIdentifier = !!disabledIdentifierMsgs?.length

  const identifierPaths = identifierRelativePath
    ? fields.map((_, i) => path + '.' + i + '.' + identifierRelativePath)
    : []

  const identifiersVals = useWatch({ name: identifierPaths })

  useEffect(() => {
    if (disabledIdentifier && fieldsLength) {
      setValue(path, min ? [{}] : []) // vymazani opakovani pokud je id disable
    }
  }, [disabledIdentifier])

  useEffect(() => {
    // pri prvnim zobrazeni formulare, doplni v kazdem existujicim opakovani data, ktera nejsou vyplnena ale maji mit nejakou default value
    if (withSchemaDefaults) {
      const oldVals = getValues(path)
      if (oldVals && oldVals.length) {
        const schemaDefValues = Array(oldVals.length).fill(defVal)
        const newVals = defaultDeepNoArray(oldVals, schemaDefValues)
        setValue(path, newVals)
      }
    }
  }, [])

  useEffect(() => {
    const c = min - fieldsLength
    if (!disabled && !disabledIdentifier && min && c > 0) {
      const oldVal = getValues(path) || []
      const value = withSchemaDefaults ? defVal : {}
      const newVal = Array(c).fill(value)
      const val = [...oldVal, ...newVal]
      setValue(path, val)
      // replace([...fields, ...Array(c).fill({})])
    }
    // if (min && fieldsLength < min) {
    //   for (let i = fieldsLength; i < min; i++) {
    //     console.log('add', i, ' je ', fieldsLength)
    //     // append({}, { shouldFocus: false })
    //     // update(i, defautlValue)
    //   }
    // }
  }, [min, disabled])

  const clickAdd = () => {
    append(defVal, { shouldFocus: false })
    setTimeout(() => {
      validateFormLive()
    }, 500)
  }
  const { deleteForbiden } = useFormDeleteForbiden()

  const clickRemove = (index: number) => {
    remove(index)
    setCloseIndex(closeIndex.filter((i) => i !== index).map((i) => (i < index ? i : i - 1)))
    deleteForbiden(`${path}.${index}`)
  }

  const clickCopy = (index: number) => {
    const vals = getValues(`${path}.${index}`)

    const newVals = cloneDeep(vals)

    if (identifierRelativePath) {
      set(newVals, identifierRelativePath, undefined)
    }

    append(newVals, { shouldFocus: false })
    const newIndex = fields.length
    setTimeout(() => {
      scrollToPath(id + '.' + newIndex)
    }, 100)
    setTimeout(() => {
      validateFormLive()
    }, 500)
  }

  return (
    <>
      <div id={id} className={'repeatSectionWrap'}>
        <PartGovDescription text={uiSchema.options?.description} />
        <PartGovErrorsBlock />
        {scope && identifierRelativePath && (
          <DynamEnumWatch arrayScope={scope} idPath={identifierRelativePath} labelPath={labelRelativePath} />
        )}
        <DisabledArrayContextContextProvider disabled={disabledIdentifier}>
          {scope &&
            elements &&
            fields.map((field, index) => {
              return (
                <IndexesContextProvider key={index} index={index}>
                  <NavigationAnchor indexArray={index}>
                    {label && (
                      <h2 className='custom-form__title gov-title--delta' title={label || undefined}>
                        <button type='button' className='form-title-toggle' onClick={() => toggleIndex(index)}>
                          {!closeIndex.includes(index) ? <Dash /> : <Plus />}
                        </button>
                        <span className='form-title-text'>
                          {index + 1}) {label}
                        </span>
                        {uiSchema.labelId && <small>({uiSchema.labelId})</small>}
                        {identifiersVals[index] && (
                          <small className='gov-badge gov-badge--inversed identifier-badge'>
                            {identifiersVals[index]}
                          </small>
                        )}
                        <div className='topRight'>
                          {copyAble && !disabled && (
                            <div className='btnwrap'>
                              <ButtonGov variant='primary' size='small' onClick={() => clickCopy(index)}>
                                {btnCopyLabel}
                              </ButtonGov>
                            </div>
                          )}
                          {!isMin && !disabled && (
                            <ButtonGov variant='primary' inversed size='small' onClick={() => clickRemove(index)}>
                              {btnRemoveLabel}
                            </ButtonGov>
                          )}
                        </div>
                        <SectionHeaderNav />
                      </h2>
                    )}
                    <div
                      key={field.id}
                      className={
                        'blockWrap custom-form u-mb--10 ' + (closeIndex.includes(index) ? 'closed-form-block' : '')
                      }
                      id={id + '.' + index}
                    >
                      <PartGovErrorsBlock index={index} />

                      <ArrayGroupInside uiSchema={uiSchema} scope={scope} index={index} />
                    </div>
                  </NavigationAnchor>
                </IndexesContextProvider>
              )
            })}
        </DisabledArrayContextContextProvider>
      </div>
      {!isFull && !disabled && (
        <div className='btnwrap arrayAddWrap'>
          <ButtonGov
            disabled={disabledIdentifier}
            variant='primary'
            size='small'
            style={{ display: 'flex', alignItems: 'baseline', columnGap: 5 }}
            onClick={() => clickAdd()}
          >
            {fieldsLength ? btnAddLabel : btnNewLabel}
            {label ? (
              <small>
                "<TextTrim text={label} length={50} />"
              </small>
            ) : null}
          </ButtonGov>
        </div>
      )}
    </>
  )
}

const ArrayGroupInside = React.memo(
  ({ uiSchema, scope, index }: { uiSchema: iUIschema; scope: string; index: number }) => {
    const ui = modifUiSchemaArray(uiSchema, scope!, index).elements
    return <RenderElements uiSchemas={ui} />
  },
)

export default React.memo(ArrayGroup)
